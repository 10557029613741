import { BriefcaseIcon, CalendarIcon, CurrencyDollarIcon, LocationMarkerIcon } from '@heroicons/react/solid';
import React from 'react';

type Props = {
  contractType: {
    id: number;
    text: string;
  };
  location: {
    id: number;
    text: string;
  };
  closeDate: {
    isPermanent: boolean;
    date: string;
  };
  salary: {
    isProvided: boolean;
    minimum: string;
    maximum: string;
  };
};

const JobHighlights = ({ contractType, location, closeDate, salary }: Props) => (
  <>
    <div className="mt-2 flex items-center text-sm text-gray-500">
      <BriefcaseIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
      {contractType.text}
    </div>
    <div className="mt-2 flex items-center text-sm text-gray-500">
      <LocationMarkerIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
      {location.text}
    </div>
    {salary.isProvided && (
      <div className="mt-2 flex items-center text-sm text-gray-500">
        <CurrencyDollarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
        {salary.minimum} &ndash; {salary.maximum}
      </div>
    )}

    <div className="mt-2 flex items-center text-sm text-gray-500">
      <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
      {closeDate.isPermanent && <div>Permanently open</div>}
      {!closeDate.isPermanent && <div>Closing on {closeDate.date}</div>}
    </div>
  </>
);

export { JobHighlights };
