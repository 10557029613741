import { ExclamationIcon } from '@heroicons/react/solid';
import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
import React from 'react';

import { JobHighlights } from '../components/PageContent/JobHighlights';
import { PageHeader } from '../components/PageHeader';
import { useJobs } from '../hooks/useJobs';

const Page = () => {
  const { hash } = useLocation();
  const jobId = Number.parseInt(hash.substring(1), 10);

  const { getJobById } = useJobs();
  const job = getJobById({ jobId });

  if (Number.isNaN(jobId) || job.length === 0) {
    return (
      <div className="mt-8  mx-auto sm:px-6 lg:max-w-7xl">
        <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
              <p className="text-sm text-yellow-700">The job you are looking for is not existing in our database.</p>
              <p className="mt-3 text-sm md:mt-0 md:ml-6">
                <Link to="/" className="whitespace-nowrap font-medium text-yellow-700 hover:text-yellow-600">
                  Open positions <span aria-hidden="true">&rarr;</span>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <PageHeader
        title={job[0].title}
        subtitle={
          <JobHighlights
            contractType={job[0].contractType}
            location={job[0].location}
            closeDate={job[0].closeDate}
            salary={job[0].salary}
          />
        }
        actions={false}
      />
      <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <section aria-labelledby="applicant-information-title" className="lg:col-start-1 lg:col-span-2">
          <div className="bg-white shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
              <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                Job description
              </h2>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:px-6 prose max-w-none">
              <b>General</b>
              <ul>
                <li>Contract Type: {job[0].contractType.text}</li>
                <li>Location: {job[0].location.text}</li>
                <li>Educational Level: {job[0].educationalLevel.text}</li>
                <li>Field of experties: {job[0].fieldOfExperties.text}</li>
                <li>Language of application: {job[0].languageOfApplication}</li>
              </ul>
              <b>Responsibilities</b>
              <ul>
                {job[0].responsibilities.map((text, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <li key={index}>{text}</li>
                ))}
              </ul>
              <b>Required competencies</b>
              <ul>
                {job[0].competencies.required.map((text, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <li key={index}>{text}</li>
                ))}
              </ul>
              {job[0].competencies.preferred.length > 0 && (
                <>
                  <b>Preferred competencies</b>
                  <ul>
                    {job[0].competencies.preferred.map((text, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <li key={index}>{text}</li>
                    ))}
                  </ul>
                </>
              )}
              <b>Language skills</b>
              <ul>
                {job[0].languageSkills.required.map((text, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <li key={index}>{text}</li>
                ))}
              </ul>
              {job[0].languageSkills.preferred.length > 0 && (
                <>
                  <b>Preferred language skills</b>
                  <ul>
                    {job[0].languageSkills.preferred.map((text, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <li key={index}>{text}</li>
                    ))}
                  </ul>
                </>
              )}
              <b>Salary</b>
              <ul>
                {job[0].salary.isProvided && (
                  <li>
                    Negotiated based on experience between {job[0].salary.minimum} and {job[0].salary.maximum}
                  </li>
                )}
                {!job[0].salary.isProvided && <li>Negotiated case by case.</li>}
              </ul>
              {job[0].benefits.bonus.length > 0 && (
                <>
                  <b>Bonus</b>
                  <ul>
                    {job[0].benefits.bonus.map((text, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <li key={index}>{text}</li>
                    ))}
                  </ul>
                </>
              )}
              {job[0].benefits.travel.length > 0 && (
                <>
                  <b>Travel</b>
                  <ul>
                    {job[0].benefits.travel.map((text, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <li key={index}>{text}</li>
                    ))}
                  </ul>
                </>
              )}
              {job[0].benefits.assets.length > 0 && (
                <>
                  <b>Assets</b>
                  <ul>
                    {job[0].benefits.assets.map((text, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <li key={index}>{text}</li>
                    ))}
                  </ul>
                </>
              )}
              {job[0].benefits.training.length > 0 && (
                <>
                  <b>Training</b>
                  <ul>
                    {job[0].benefits.training.map((text, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <li key={index}>{text}</li>
                    ))}
                  </ul>
                </>
              )}
              {job[0].benefits.wellbeing.length > 0 && (
                <>
                  <b>Wellbeing</b>
                  <ul>
                    {job[0].benefits.wellbeing.map((text, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <li key={index}>{text}</li>
                    ))}
                  </ul>
                </>
              )}
              {job[0].benefits.catering.length > 0 && (
                <>
                  <b>Catering</b>
                  <ul>
                    {job[0].benefits.catering.map((text, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <li key={index}>{text}</li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          </div>
        </section>
        <section aria-labelledby="timeline-title" className="lg:col-start-3 lg:col-span-1">
          <div className="bg-white shadow sm:rounded-lg">ÁÁÁ</div>
        </section>
      </div>
    </>
  );
};

export default Page;
